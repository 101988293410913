import * as React from "react";
import Header, {HeaderNav} from "../../components/header";
import Seo from "../../components/seo"

export default () => {
  return (<>
    <Header selected={HeaderNav.Chance}/>
  </>)
}

export const Head = () => <Seo />
